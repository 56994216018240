.modal {
    display: flex;
    background-color: white;
    position: fixed;
    top: 1%;
    left: 37%;
    width: 30%;
    height: auto;
    border-radius: 10px;
    z-index: 9999;
}

.modal-dialog{
    width: 100%;
    height: 100%;
    margin: 2%;
}

.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px;
}

.modal-button{
    color: black;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.modal-body audio{
    width: 100%;
}

@media only screen and (max-width: 900px){
    .modal{
        width: 80%;
        top:10%;
        left: 10%;
    }

    .modal-body audio{
        width: 100%;
    }
}