.loader {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: linear-gradient(0.15deg,
            rgba(70, 81, 102, 0.7) 0.15%,
            rgba(70, 81, 102, 0) 99.89%);
    position: absolute;
    top: 0px;
    z-index: 100000;

    .container {
        height: 4px;
        width: 160px;
        background-color: white;
        border-radius: 2px;
        margin-top: 11px;
    }

    .progress {
        height: 4px;
        background: linear-gradient(225deg, $brand-secondary 0%, #44d511 100%);
        border-radius: 2px;
    }
}