@import '../theme.scss';

.dropdown {
    position: relative;
    width: 210px;
    height: 36px;
}

.dropdown::before {
    content: "";
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1000;
    width: 6px;
    height: 6px;
    border: 2px solid $icon;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    transform: rotate(-45deg);
    transition: 0.5s;
    pointer-events: none;
}

.dropdown.active::before {
    top: 16px;
    transform: rotate(-225deg);
}

.dropdown.inactive::before{
    content: none;
}

.dropdown input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: $white;
    font-size: 12px;
    font-weight: bolder;
    text-transform: uppercase;
    border: none;
    outline: none;
    box-shadow: $shadow;
    padding: 12px 20px;
    border-radius: 50px;
}

.dropdown .options {
    position: absolute;
    top: 40px;
    width: 100%;
    background: $white;
    box-shadow: $shadow;
    border-radius: 10px;
    // overflow: hidden;
    display: none;
    font-size: 14px;
}

.dropdown.active {
    background-color: transparent;
}

.dropdown.active .options {
    display: block;
}

.dropdown .options div {
    padding: 8px 20px;
    cursor: pointer;
}

.dropdown .options div:hover {
    font-weight: bolder;
}

.dropdown .options div ion-icon {
    position: relative;
    top: 4px;
    font-size: 1.2em;
    margin-right: 10px;
}

.audioDropdown {
    position: relative;
    width: 200px;
    height: 36px;
}

.audioDropdown::before {
    content: "";
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1000;
    width: 6px;
    height: 6px;
    border: 2px solid $icon;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    transform: rotate(-45deg);
    transition: 0.5s;
    pointer-events: none;
}

.audioDropdown.active::before {
    top: 16px;
    transform: rotate(-225deg);
}

.audioDropdown input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: $white;
    font-size: 12px;
    font-weight: bolder;
    text-transform: uppercase;
    border: none;
    outline: none;
    box-shadow: $shadow;
    padding: 12px 20px;
    border-radius: 50px;
}

.audioDropdown .AudioOptions {
    position: absolute;
    top: 40px;
    right:0px;
    width: 100%;
    background: $white;
    box-shadow: $shadow;
    border-radius: 10px;
    // overflow: hidden;
    display: none;
    font-size: 14px;
}

.audioDropdown.active {
    background-color: transparent;
}

.audioDropdown.active .AudioOptions {
    display: block;
}

.audioDropdown .AudioOptions div {
    padding: 8px 20px;
    cursor: pointer;
}

.audioDropdown .AudioOptions div:hover {
    font-weight: bolder;
}

.audioDropdown .AudioOptions div ion-icon {
    position: relative;
    top: 4px;
    font-size: 1.2em;
    margin-right: 10px;
}