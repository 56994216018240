@import "../theme.scss";

.controls {
    position: absolute;
    right: 20px;
    bottom: 20px;

    img {
        height: 20px;
    }

    li {
        background-color: $white;
        height: 40px;
        width: 40px;
    }

    button {
        height: 100%;
        width: 100%;
        border: 0px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: box-shadow .3s;
    }

    button:hover {
        box-shadow: $shadow;
    }

    button:disabled {
        cursor: unset;
        opacity: 0.2;
    }

    //360 icon
    li:first-child {
        margin-bottom: 10px;
        background-color: transparent;

        img {
            height: 25px;
        }
    }

    //full screen
    li:nth-child(2) {
        margin-bottom: 10px;
        border-radius: 8px;
    }

    //zoom out
    li:nth-child(3) {
        border-radius: 8px 8px 0px 0px;
    }

    //zoom in
    li:nth-child(4) {
        border-top: 2px solid $border;
        border-radius: 0px 0px 8px 8px;
    }

    @media only screen and (max-width: 900px) {
        left: 0px;
        bottom: 85px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;

        li:nth-child(3),
        li:nth-child(4) {
            display: none;
        }
    }
}