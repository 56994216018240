@import "./theme.scss";
@import "./views/viewer.scss";
@import "./views/gallery.scss";
@import "./components/controls.scss";
@import "./components/tabs.scss";
@import "./components/dropdown.scss";
@import "./components/hotspot.scss";
@import "./components/loader.scss";
@import "./components/modal.scss";

.App {
  text-align: center;
  color: $brand-primary;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p,
h1,
h2,
h3 {
  margin: 0;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin: 0;
}

.hide-mobile {
  display: block;
}

.hideDesktop {
  display: none;
}

.error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.panButton {
  bottom: 30% !important;
  right: 20px !important;
  height: 40px !important;
  width: 40px !important;
  padding: 5px !important;
}

.panView {
  right: 5% !important;
}

.error-screen {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .error-box {
    max-width: 70%;
    border: 1px solid black;
    color: black;
    padding: 25px 30px;
    font-size: 14px;
    background-color: rgba(255, 0, 0, 0.2);
    text-align: center;
  }
}

@media only screen and (max-width: 900px) {
  .hide-mobile {
    display: none !important;
  }

  .hideDesktop {
    display: block;
  }

  .panButton {
    bottom: 22% !important;
    right: 10px !important;
  }

  .panView {
    right: 55px !important;
  }
}
