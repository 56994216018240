@import "../theme.scss";

.tabs {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 100001;

    ul {
        display: flex;
        position: relative;
        overflow: hidden;
        z-index: 0;
        --i: -1;
    }

    li {
        position: relative;
        height: 36px;
        width: 110px;
        background-color: $white;
        padding: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-right: 1px solid $border;
    }

    li:hover {
        box-shadow: $shadow;
    }

    li:first-child {
        border-radius: 50px 0 0 50px;
        border-right: 1px solid $border;
    }

    li:last-child {
        border-radius: 0 50px 50px 0;
        border-right: 0px;
    }

    input {
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }

    label {
        font-size: 12px;
        font-weight: bolder;
        text-transform: uppercase;
        min-width: calc(100% / var(--length));
        position: relative;
        z-index: 2;
        float: left;
        text-align: center;
        text-shadow: none;
        position: relative;
        //transition: color .3s ease-in-out;
        cursor: pointer;
        pointer-events: none;
    }

    //active marker
    // ul:before {
    //     content: "";
    //     position: absolute;
    //     z-index: 1;
    //     width: calc(100% / var(--length));
    //     left: calc(var(--i) * (100% / var(--length)));
    //     height: 100%;
    //     background: $brand-primary;
    //     transition: .3s ease-in-out;
    //     cursor: pointer;
    // }

    .active {
        background: $brand-primary;
    }

    // //curved edges first item
    // .active-first:before {
    //     border-radius: 50px 0 0 50px;
    // }

    // //curved edges last item
    // .active-last:before {
    //     border-radius: 0 50px 50px 0;
    // }

    input:checked+label {
        color: $white;
    }

    .dropdown .options {
        text-transform: uppercase;
    }

    @media only screen and (max-width: 900px) {
        left: 5px;

        input {
            opacity: 1;
        }

        .dropdown {
            width: 144px;
        }
    }
}