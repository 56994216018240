@import "../theme.scss";

.viewer {
    transition: all linear 0.3s;

    .imgContainer {
        position: relative;
        height: 100vh;
        width: 100vw;
        background-color: #f1f1f1;
    }

    .imgContainer>div {
        height: 100%;
        width: 100%;
    }

    .fullscreen {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #f1f1f1;
        top: 0;
        left: 0;
    }

    .cloudimage-inner-box {
        height: 100% !important;
    }

    .cloudimage-inner-box img {
        height: 100% !important;
        width: 100% !important;
    }
}