@import "../theme.scss";

.hotspotContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    bottom: 10px;
    max-height: 440px;
    width: 570px;
    z-index: 1;
    background-color: $white;
    padding: 10px;
    text-align: left;

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        padding:3px;
        cursor: pointer;
        border: 0.5px solid rgba(0, 0, 0, 0.474);
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    .img {
        height: 309px;
        width: 100%;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .title {
        font-size: 24px;
        margin: 10px 0px;
    }

    a {
        color: $brand-secondary;
        font-size: 14px;
        text-decoration: none;
    }

    @media only screen and (max-width: 900px) {
        top: 85%;
        height: 177px;
        width: 250px;

        .img {
            height: 123px;
        }

        .title {
            font-size: 15px;
        }
    }
}