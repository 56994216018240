.gallery {

    .arrowDown{
        position: absolute;
        bottom: 8%;
    }


    .imgContainer {
        overflow: hidden;

        .imgNameOverlay{
            position: absolute;
            bottom: 1%;
            left:0%;
            background-color: rgb(255, 255, 255);
            min-width: 300px;
            min-height: 45px;
            border-radius: 10px;
            padding: 20px;
        }

        .modal {
            display: flex;
            background-color: white;
            position: fixed;
            top: 30%;
            left: 17%;
            width: 60%;
            height: auto;
            border-radius: 10px;
            z-index: 9999;
        }
        
        .modal-dialog{
            width: 100%;
            height: 100%;
            margin: 2%;
        }
        
        .modal-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            margin: 5px;
        }
        
        .modal-button{
            color: black;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
        
        .modal-body audio{
            width: 100%;
        }


@media only screen and (max-width: 900px){
    .modal{
        width: 80%;
        top:30%;
        left: 10%;
    }

    .modal-body audio{
        width: 100%;
    }
}
    }

    .imgContainer img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        display: none;
    }

    .imgContainer video {
        width: 90%;
        height: 90%;
        display: none;
        margin: 1% auto;
        align-self: center;
        align-items: center;
        justify-content: center;
    }

    .thumbnail {
        position: absolute;
        right: 20px;
        max-height: 650px;
        display: flex;
        padding: 10px;
        border: 0.5px solid rgba(179, 179, 179, 0.534);
        border-radius: 3px;
        flex-direction: column;
        overflow-y: scroll;
        top: 47%;
        backdrop-filter: blur(50px);
        transform: translateY(-50%);
        -ms-overflow-style: scrollbar ; //IE & edge
        scrollbar-width: 1px; //firefox

        .videoThumb img{
            object-fit: contain;
            width:60%;
            padding:10px;
        }

        .audioThumb img{
            object-fit: contain;
            width:60%;
            padding:10px;
        }
    }

    .thumbnail::-webkit-scrollbar {
        //safari, chrome & opera
        display: none;
    }

    .thumbnail img {
        height: 80px;
        width: 144px;
        object-fit: contain;
        cursor: pointer;
        margin-bottom: 10px;

    }

    .thumbnail .active {
        border: 2px solid $brand-secondary;
    }

    .dropdown {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
    }

    .controls li:first-child img {
        display: none;
    }
    .audio{
        position: relative;
        top:0;
        right:100%;
        justify-content: end;
    }

    @media only screen and (max-width: 900px) {

        .dropdown,
        .thumbnail {
            right: 5px;
        }

        .thumbnail {
            flex-direction: row;
            bottom: 5px;
            transform: none;
            top: auto;
            overflow-y: hidden;
            overflow-x: scroll;
            height: auto;
            right: 50%;
            transform: translate(50%);
            width: 100%;


        .videoThumb img{
            object-fit: contain;
            height: 60px;
            width: 100px;
            padding:0px;
            margin: 0px;
        }

        .audioThumb img{
            object-fit: contain;
            height: 60px;
            width: 100px;
            padding:0px;
            margin: 0px;
        }

    }

        .thumbnail img {
            height: 60px;
            width: 144px;
            margin-right: 10px;
            margin-bottom: 5px;
        }

        
        .imgContainer video {
            height: 80%;
            display: none;
        }
            
        .audio{
            position: absolute;
            top:70vh;
            right: 0;
            color: black;
        }

        .imgContainer {
            overflow: hidden;
    
            .imgNameOverlay{
                position: absolute;
                bottom: 14%;
                left:1%;
                background-color: rgb(255, 255, 255);
                min-width: 30%;
                min-height: 7%;
                max-width: 400px;
                max-height: 50px;
                border-radius: 10px;
                padding: 20px;
            }
        }

    }
}